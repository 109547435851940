<template>
  <el-dialog
    :title="type==='create'?'新增':'编辑'"
    :visible.sync="visible"
    custom-class="createDialog dataM"
    width="650px"
    :close-on-click-modal="false"
    @close="handleClose"
    >
    <el-form :model="form" :rules="rules" 
      ref="ruleForm" class="dialogContent" 
      :show-message="false"
    >
      <div class="left">
        <el-form-item prop="equipmentNo" class="item" label="设备编号">
          <el-input v-model.trim="form.equipmentNo" clearable/>
        </el-form-item>
        <el-form-item prop="equipmentName" class="item" label="名称">
          <el-input v-model.trim="form.equipmentName" clearable/>
        </el-form-item>
        <el-form-item  prop="equipmentType" class="item" label="设备类别">
          <el-select v-model.number="form.equipmentType" clearable>
            <el-option v-for="item in equipmentTypeList" :key="item" :label="item" :value="item"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="category" class="item" label="属性">
          <el-select v-model="form.category">
            <el-option v-for="(v,i) in categoryList" :key="i" :value="v">{{v}}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="workshiftSchemeName" class="item" label="日历方案">
          <el-select v-model="form.shiftId" clearable>
            <el-option v-for="(v,i) in maintenancePlanList" :key="i" :value="v.shiftId" :label="v.workshiftSchemeName">
              {{v.workshiftSchemeName}}
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item prop="receiveOrderType" class="item" label="接单方式">
          <el-select v-model.number="form.receiveOrderType">
            <el-option :value="1" label="正常"/>
            <el-option :value="2" label="散件"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="equipmentStatus" class="item" label="设备状态">
          <el-select v-model.number="form.equipmentStatus">
            <el-option :value="1" label="正常"/>
            <el-option :value="2" label="故障"/>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.equipmentStatus ==2" prop="errtime" class="item" label="故障时间">
          <el-date-picker
            v-model="form.errtime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            popper-class="dataM"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="maintenancePlan" class="item" label="保养计划">
          <el-date-picker v-model="form.maintenancePlan" value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item prop="maintenanceDuration" class="item" label="保养时长">
          <el-time-picker
            v-model="form.maintenanceDuration"
            value-format="HH:mm"
            format="HH:mm"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59'
            }"
          />
        </el-form-item>
        <el-form-item prop="maintenanceItems" class="item" label="保养项目">
          <div v-for="(item,index) in form.maintenanceItems" :key="index">
            <el-input v-model.trim="item.value" style="margin:10px 0px" clearable/>
          </div>
          <el-button icon="el-icon-plus" style="width:100%" @click="addItem()"></el-button>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取消</el-button>
      <el-button type="primary" @click="handleSure()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { 
  addProEquipment,
  editProEquipment,
  getWorkshift
  } from '@/api/data.js'
export default {
  name:'createStaff',
  props:{
    currentData:{
      require:false,
      default:()=>{}
    },
    createVisible:{
      require:true,
      default:false
    },
    type:{
      default:'edit'
    }
  },
  data(){
    return{
      that:this,
      form:{
        equipmentNo:'',
        equipmentName:'',
        equipmentStatus:1,
        equipmentParameters:'',
        equipmentType:'',//机床类型
        category:'',
        workshiftSchemeName:'',
        shiftId:'',
        maintenancePlan:null,
        maintenanceDuration:null,
        maintenanceItems:[{
          value:''
        }],
        receiveOrderType:1,//接单方式
        errtime:null,//故障时间
        faultStartTime:null,//故障开始时间
        faultEndTime:null,//故障结束时间
        // length:'',
        // width:'',
        // height:''
      },
      rules:{
        equipmentNo:[
          { required: true, message: '请输入设备编号', trigger: 'change' },
        ],
        equipmentName:[
          {required: true, message: '请输入设备名称', trigger: 'change'}
        ],
        equipmentParameters:[
          {required: true, message: '请输入设备参数', trigger: 'change'}
        ],
        category:[
          {required: true, message: '请选择属性', trigger: ['change','blur']}
        ],
        equipmentStatus:[
          {required: true, message: '请选择设备状态', trigger: ['change','blur']}
        ],
        // equipmentType:[
        //   {required: true, message: '请选择设备类型', trigger: ['change','blur']}
        // ],
        receiveOrderType:[
          {required: true, message: '请选择接单方式', trigger: ['change','blur']}
        ]
      },
      categoryList:['数车','数铣','其他','线切割'],
      equipmentTypeList:[
        '三轴','三轴快反','三轴零点','四轴','四轴快反','四轴零点','五轴','五轴快反','五轴零点','数车','数车尾顶','数车双主轴','车铣','车铣尾顶','车铣双主轴','慢走丝','烤箱'
      ],
      maintenancePlanList:[],
    }
  },
  computed:{
    visible:{
      get(){
        return this.createVisible
      },
      set(){}
    },
  },
  watch:{
    createVisible:{
      handler(val){
        if(val){
          if(this.type === 'edit' && JSON.stringify(this.currentData) !== '{}'){
            let { maintenanceItems,faultStartTime,faultEndTime} = this.currentData
            this.form = {
              ...this.currentData,
              errtime:faultStartTime ? [faultStartTime,faultEndTime] : null,
              maintenanceItems:maintenanceItems.length ? maintenanceItems.map(item=>{
                return {
                  value:item
                }
              }) : [{value:''}]
            }
          }
          this.getWorkList()
        }
      },
      immediate:true
    },
    // currentData(){
    //   if(this.type === 'edit' && JSON.stringify(this.currentData) !== '{}'){
    //     let { maintenanceItems,faultStartTime,faultEndTime} = this.currentData
    //     this.form = {
    //       ...this.currentData,
    //       errtime:faultStartTime ? [faultStartTime,faultEndTime] : null,
    //       maintenanceItems:maintenanceItems.length ? maintenanceItems.map(item=>{
    //         return {
    //           value:item
    //         }
    //       }) : [{value:''}]
    //     }
    //   }
    // }
  },
  methods:{
    getWorkList(){
      let params = {
        page:1,
        size:999
      }
      getWorkshift(params).then(res=>{
        if(res.code === 0){
          this.maintenancePlanList = res.data.records
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      }).catch(()=>{
        this.$message.error('接口出错')
      })
    },
    handleClose(){
      this.$emit('update:createVisible',false)
    },
    handleSure(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.handleSubmit()
        } else {
          this.$message.error('请补充完整信息')
          return false;
        }
      });
    },
    handleSubmit(){
      let params = {
        ...this.form,
        maintenanceItems:this.form.maintenanceItems.map(v=>v.value),
        workshiftSchemeName:this.maintenancePlanList.find(v=>v.shiftId == this.form.shiftId)?.workshiftSchemeName || '',
      }
      if(this.form.errtime?.length){
        params.faultStartTime = this.form.errtime[0]
        params.faultEndTime = this.form.errtime[1]
      }else{
        delete params.faultStartTime
        delete params.faultEndTime
      }
      let Api = this.type === 'edit'? editProEquipment : addProEquipment
      // return console.log('===',params)
      Api(params).then(res=>{
        if(res.code == 0){
          this.$emit('update:createVisible',false)
          this.$emit('handleSearch')
          this.$message.success('操作成功')
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    //添加保养项目
    addItem(){
      this.form.maintenanceItems.push({value:""})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<style lang="scss">
.createDialog{
  .el-form-item{
    margin-bottom:10px!important;
  }
  .el-form-item__label{
    font-size: 14px;
    line-height: 28px!important;
  }
  .el-select,.el-input,.el-date-editor{
    width: 100%!important;
  }
  .el-form-item.is-error .el-input__inner{
    border-color: #F56C6C!important;
  }
  .dialogContent{
    display:flex;
    .right{
      margin-left: 60px;
    }
    .right,.left{
      display: flex;
      flex-direction: column;
      align-items: space-between;
      .item{
        .item2{
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>